import {mapActions, mapGetters} from "vuex";

export default {
  data(){
    return {
      immediateActsMain: {},
      immediateActsSub: {},
      immediateConditionsMain: {},
      immediateConditionsSub: {},
      basicCauseMain: {},
      basicCauseSub: {},
    }
  },
  methods:{
    ...mapActions(['getSubCauseKeys','getMainCauseKeys']),
    async getImmediateActsMain() {
      const params = {
        cause_type_id: 2,
        cause_type_class_id: 1
      }
      await this.getMainCauseKeys(params)
      this.immediateActsMain = this.mainCauseKeys
    },
    // get immediate substandard condition main dropdown
    async getImmediateConsMain() {
      const params = {
        cause_type_id: 2,
        cause_type_class_id: 2
      }
      await this.getMainCauseKeys(params)
      this.immediateConditionsMain = this.mainCauseKeys
    },
    // get root basic cause
    async getBasicCauseMain(){
      const params = {
        cause_type_id: 1,
        cause_type_class_id: 0
      }
      await this.getMainCauseKeys(params)
      this.basicCauseMain = this.mainCauseKeys
    },
  },
  computed:{
    ...mapGetters(['mainCauseKeys', 'subCauseKeys',])
  },

  async created(){
    await this.getImmediateActsMain()
    await this.getImmediateConsMain()
    await this.getBasicCauseMain()
  },

}
