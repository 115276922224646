<script>
import CauseMixin from "@/mixins/CauseMixin";
import {mapActions, mapGetters} from "vuex";
import RsNonConformityMixin from "@/mixins/RsNonConformityMixin";
import ObjectMixin from "@/mixins/ObjectMixin";

export default {
  name: "NonConformityDetail",
  data() {
    return {
      toolbar: [
        ['bold', 'italic', 'strike', 'underline'],
        [{
          'color': ["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff",
            "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff",
            "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff",
            "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2",
            "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466"]
        }]
      ],
      nonConformityField:{},
    }
  },
  computed:{
    ...mapGetters(['editNonConformity','nonConformity'])
  },
  methods:{
    ...mapActions(['updateRsNonConformity']),
  },
  mixins:[CauseMixin,RsNonConformityMixin,ObjectMixin],
  watch:{
    'nonConformityField.immediate_cause_id': async function () {
      if (this.nonConformityField.immediate_cause_id === 36) {
        const params = {
          main_cause_id: this.nonConformityField.immediate_cause_id
        }
        await this.getSubCauseKeys(params)
        this.immediateActsSub = this.subCauseKeys
      } else {
        this.nonConformityField.immediate_cause_child_id=null
        this.immediateActsSub = {}
      }
    },
    'nonConformityField.root_cause_id': async function() {
      const params = {
        main_cause_id: this.nonConformityField.root_cause_id
      }
      await this.getSubCauseKeys(params)
      this.basicCauseSub = this.subCauseKeys
    }
  }
}
</script>

<template>
  <form @submit.prevent="confirmUpdateRsNonConformity">
    <div class="row">
      <div class="col-12 text-right">
        <small class="ml-5">
          <strong>Note :</strong> The structure and description of Immediate Cause and Root Cause follow the DNV Marine Systematic Cause.
        </small>
        <template v-if="editNonConformity">
          <button class="e-btn e-btn-outlined-red ml-2" type="button" @click="setNonConformityEdit()" v-if="editNonConformity">Cancel</button>
          <button class="e-btn e-btn-blue ml-2" type="submit">Save</button>
        </template>
        <template v-else>
          <button class="e-btn e-btn-green ml-2 px-3" type="button" @click="setNonConformityEdit(true)">Edit Non Conformity Details</button>
        </template>
      </div>
    </div>
    <table class="w-100">
      <tr>
        <td style="width: 25%">Questioner Section</td>
        <td>
          {{nonConformity.rsn_question.parent.main_question}}
        </td>
      </tr>
      <tr>
        <td style="width: 25%">Question No.</td>
        <td>
          {{nonConformity.rsn_question.code}}
        </td>
      </tr>
      <tr>
        <td style="width: 25%">Question</td>
        <td>
          {{nonConformity.rsn_question.main_question}}
        </td>
      </tr>
      <tr>
        <td style="width: 25%">Description of Finding</td>
        <td>
          <vue-editor  class="my-2"  v-if="editNonConformity" :editor-toolbar="toolbar" v-model="nonConformityField.description"></vue-editor>
          <div v-else class="pre-wrap-txt textarea-display-card my-2" v-html="nonConformity.description"/>
        </td>
      </tr>
      <tr>
        <td colspan="2">
          <h6 class="font-weight-bold">Immediate Cause</h6>
        </td>
      </tr>
      <tr>
        <td colspan="2">
          <hr />
        </td>
      </tr>
      <tr>
        <td style="width: 25%">a) Substandard Act <sub class="lead e-text-red font-weight-bold">*</sub></td>
        <td>
          <template v-if="editNonConformity">
            <select class="form-control text-uppercase form-control-sm mb-2" v-model="nonConformityField.immediate_cause_id">
              <option :value="null">—— SELECT SUBSTANDARD ACT ——</option>
              <option v-for="act in immediateActsMain" :key="act.id" :value="act.id">
                {{ act.series + '_' }}
                {{ act.name }}
              </option>
            </select>
            <template v-if="immediateActsSub.length">
              <select class="form-control form-control-sm mt-1 text-uppercase mb-2" v-model="nonConformityField.immediate_cause_child_id">
                <option :value="null">—— SELECT SUBSTANDARD CHILD ——</option>
                <option v-for="act in immediateActsSub" :key="act.id" :value="act.id">
                  {{ act.series + '_' }}
                  {{ act.name }}
                </option>
              </select>
            </template>
          </template>
          <span v-else>
            {{
              nonConformity.immediate_cause_id ? nonConformity.immediate_cause.name.toUpperCase() : ''
            }}
            {{ nonConformity.immediate_cause_child_id ? '/' : '' }}
            {{
              nonConformity.immediate_cause_child_id ? nonConformity.immediate_cause_child.name.toUpperCase() : ''
            }}
          </span>
        </td>
      </tr>
      <tr>
        <td style="width: 25%">b) Substandard Condition</td>
        <td>

          <template v-if="editNonConformity">
            <select class="form-control form-control-sm text-uppercase" v-model="nonConformityField.immediate_cause_sub_id">
              <option :value="null">—— SELECT SUBSTANDARD CONDITION ——</option>
              <option v-for="condition in immediateConditionsMain" :key="condition.id" :value="condition.id">
                {{ condition.series + '_' }}
                {{ condition.name }}
              </option>
            </select>
          </template>
          <span v-else>
            {{
              nonConformity.immediate_cause_sub_id ? nonConformity.immediate_cause_sub.name.toUpperCase() : ''
            }}
          </span>
        </td>
      </tr>
      <tr>
        <td style="width: 25%">Cause Detail</td>
        <td>
          <vue-editor  class="my-2" v-if="editNonConformity" v-model="nonConformityField.immediate_cause_detail" :editor-toolbar="toolbar"></vue-editor>
          <div v-else class="pre-wrap-txt textarea-display-card my-2" v-html="nonConformity.immediate_cause_detail"/>
        </td>
      </tr>
    </table>
    <hr/>
    <table class="w-100">
      <tr>
        <td colspan="2">
          <h6 class="font-weight-bold">Root Cause</h6>
        </td>
      </tr>
      <tr>
        <td style="width: 25%">a) Root Cause <sub class="lead e-text-red font-weight-bold">*</sub></td>
        <td>
          <select class="form-control form-control-sm text-uppercase mb-2" v-if="editNonConformity" v-model="nonConformityField.root_cause_id">
            <option :value="null">—— select root cause ——</option>
            <option v-for="factor in basicCauseMain" :key="factor.id" :value="factor.id">
              {{ factor.series + '_' }}
              {{ factor.name }}
            </option>
          </select>
          <span v-else>
           {{
              nonConformity.root_cause_id ? nonConformity.root_cause.name.toUpperCase() : ''
            }}
          </span>
        </td>
      </tr>
      <tr>
        <td style="width: 25%">b) Factor of Root Cause</td>
        <td>
          <select class="form-control form-control-sm text-uppercase"
                  v-if="editNonConformity" v-model="nonConformityField.root_cause_sub_id">
            <option :value="null">—— select sub factors ——</option>
            <option v-for="factor in basicCauseSub" :key="factor.id" :value="factor.id">
              {{ factor.series + '_' }}
              {{ factor.name }}
            </option>
          </select>
          <span v-else>
            {{nonConformity.root_cause_sub_id ? nonConformity.root_cause_sub.name.toUpperCase() : '' }}
          </span>
        </td>
      </tr>
      <tr>
        <td style="width: 25%">Cause Detail</td>
        <td>
          <vue-editor class="my-2" v-if="editNonConformity" :editor-toolbar="toolbar" v-model="nonConformityField.root_cause_detail">
          </vue-editor>
          <div v-else class="pre-wrap-txt textarea-display-card my-2" v-html="nonConformity.root_cause_detail"/>
        </td>
      </tr>
    </table>
  </form>
</template>

<style scoped>
table td {
  padding: 2px !important;
}
</style>
