import {mapActions, mapGetters} from "vuex";
import {AlertService} from "@/services/AlertService";
import attachmentMixin from "@/mixins/attachmentMixin";

export default  {
  data(){
    return {
      selectedId:null,
      nonConformityField:{},
      nonConformityId:null,
    }
  },
  mixins:[attachmentMixin],
  computed:{
    ...mapGetters(['editNonConformity','nonConformity','rsNonConformities']),
  },
  methods:{
    ...mapActions(['updateRsNonConformity','renderNonConformityAttachments']),
    async confirmUpdateRsNonConformity(){
          if(await AlertService.confirmUpdateAlert('Do you want to update Non Conformity?','Yes, update it!')){
              const response = await this.updateRsNonConformity(this.nonConformityField);
              if(response) {
                  AlertService.successAlert('Non conformity detail updated Successfully', 'Update Non Conformity Detail')
                  this.resetNonConformity()
              }
          }
    },
    async setNonConformityEdit(state= false) {
     if (this.editNonConformity) {
          if (await AlertService.cancelAlert()) {
              this.resetNonConformity()
              this.nonConformityField=Object.assign({},this.nonConformity)
              this.$store.commit('SET_EDIT_NON_CONFORMITY',state)
         }
     }
     else{
         this.nonConformityField=Object.assign({},this.nonConformity)
         this.$store.commit('SET_EDIT_NON_CONFORMITY',state)
     }

   },
    async viewNonConformity(conformity){
      this.nonConformityId = conformity.id
      this.$router.push({name:"NonConformityDetail",query:{tz:Date.now()}})
      localStorage.setItem('currentNonConformity',JSON.stringify(conformity));
      this.$store.commit('SET_EDIT_NON_CONFORMITY', false)
      this.$store.commit('SET_NON_CONFORMITY',JSON.parse(localStorage.getItem('currentNonConformity')))
      let params={
          id:this.$route.params.id,
          non_conformity_id:conformity.id
      }
      await this.renderNonConformityAttachments(params)


    },

    resetNonConformity(){
        const jsonParsed = JSON.parse(localStorage.getItem('currentNonConformity'));
        if (jsonParsed.id == this.nonConformityId) {
            let nonConformityObj = this.rsNonConformities;
            nonConformityObj = nonConformityObj.find(obj => obj.id == event.id)
            localStorage.setItem('currentDeficiency', JSON.stringify(nonConformityObj))
        }
        const nonConformity=JSON.parse(localStorage.getItem('currentNonConformity'))
        this.$store.commit('SET_EDIT_NON_CONFORMITY', false)
        this.$store.commit('SET_NON_CONFORMITY',nonConformity)
        this.nonConformityId=nonConformity
    },


    preventRouteChange(event) {
      if (!this.toEdit) return
      event.preventDefault()
      event.returnValue = ""
    },

  },
  async beforeRouteLeave(to,from,next){
    if (this.editNonConformity) {
      if (await AlertService.cancelAlert()) {
        this.resetNonConformity()
      }
      else {
        return;
      }
    }
    next();
  },

  beforeMount() {
    window.addEventListener("beforeunload", this.preventRouteChange)
    this.$once("hook:beforeDestroy", () => {
      window.removeEventListener("beforeunload", this.preventRouteChange);
    })
  }
}
